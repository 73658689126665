import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import * as moment from 'moment/moment';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {DateService} from '../../Services/date.service';
import {UserAccessService} from '../../Services/user-access.service';
import {map, switchMap} from 'rxjs/operators';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GlobalFunctionsService} from '../../Services/global-functions';
import {environment} from "../../../environments/environment";
import {EnvironmentTypes} from "../../Enums/environment-types";
import {BatchForWeekReport, BatchToShowInReport, BatchToShowOnSingleDay, Execution, Weighing} from "./batch.interface";


@Component({
    selector: 'app-week-report',
    templateUrl: './week-report.component.html',
    styleUrls: ['./week-report.component.scss']
})
export class WeekReportComponent implements OnInit, OnDestroy {

    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public batches: any;
    public batchesToShowInReport: BatchToShowOnSingleDay[];
    private subscription$: Subscription;
    displayedColumns: string[] = ['Datum', 'Zeit (ist)', 'Dauer', 'Charge', 'Aufgabe', 'Produkt'];

    constructor(public dialog: MatDialog,
                public dateService: DateService,
                private Apiservice: APIService,
                public functionsService: GlobalFunctionsService,
                public userAccess: UserAccessService) {
    }


    ngOnInit(): void {
        if (this.Apiservice.versioningFinished === true) {
            this.createSubscriptionFordate();
        } else {
            this.Apiservice.OnVersioningFinished.subscribe(() => {
                this.createSubscriptionFordate();
            });
        }
    }

    createSubscriptionFordate() {
        this.subscription$ = this.dateService.date.pipe(switchMap(data => this.generate(data))).subscribe(() => {
            },
            error => {
                this.functionsService.handleServerNotReachableError();
            }
        );
    }

    generate(now: moment.Moment): Observable<void> {
        const week = now.clone().week();
        const year = now.clone().year();
        const startOfWeek = now.clone().startOf('week');
        const endOfWeek = now.clone().endOf('week');

        // Clear the current batches
        this.batches = [];

        return this.Apiservice.getBatchListByCwReport(year, week).pipe(
            map((data: BatchForWeekReport[]) => {
                this.batches = data;
                const result: BatchToShowOnSingleDay[] = [];

                // Process all batches
                this.batches.forEach(bt => {
                    // Include batches that have executions
                    bt.executions.forEach(execution => {
                        if (execution.started && execution.ended) {
                            const startDate = moment(execution.started);
                            const endDate = moment(execution.ended);

                            // Constrain execution range to the current week
                            const boundedStart = moment.max(startOfWeek, startDate);
                            const boundedEnd = moment.min(endOfWeek, endDate);

                            // Process each day in the bounded range
                            this.populateDaysForExecution(boundedStart, boundedEnd, result, bt, execution);
                        }
                    });

                    console.log(bt.weighings);

                    // Additionally include batches that have weighings
                    if (bt.weighings && bt.weighings.length > 0) {
                        const earliestWeighing = bt.weighings[0]?.time
                            ? moment(bt.weighings[0].time)
                            : startOfWeek;

                        const boundedStart = moment.max(startOfWeek, earliestWeighing);
                        const boundedEnd = moment.min(endOfWeek, earliestWeighing); // Assuming weighings are single-day

                        // Add data for batches with weighings to the result
                        this.populateDaysForExecution(boundedStart, boundedEnd, result, bt, null);
                    }
                });

                // Sort result by date (ascending order)
                result.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());

                // Assign sorted result to the observable source
                this.batchesToShowInReport = result;
                console.log(this.batchesToShowInReport);
            })
        );
    }


    /**
     * Populates batches for each day for a given execution.
     */
    private populateDaysForExecution(
        executionStart: moment.Moment,
        executionEnd: moment.Moment,
        result: BatchToShowOnSingleDay[],
        batch: BatchForWeekReport,
        execution: Execution | null // Allow null for weighings-only batches
    ): void {
        for (
            let currentDate = executionStart.clone();
            currentDate.isSameOrBefore(executionEnd, 'day');
            currentDate.add(1, 'day')
        ) {
            // Calculate day boundaries
            const dayStart = currentDate.clone().startOf('day');
            const dayEnd = currentDate.clone().endOf('day');

            // If execution is null (weighings-only case), skip duration calculation
            const executionDuration = execution
                ? this.calculateExecutionDuration(
                    dayStart,
                    dayEnd,
                    moment(execution.started),
                    moment(execution.ended)
                )
                : 0;

            // Find or create the batch for the current day
            let batchForDay = result.find(
                b => b.date === currentDate.format('YYYY-MM-DD') && b.id === batch.id
            );

            if (!batchForDay) {
                // Filter weighings for this day
                const weighingsForDay = this.filterWeighingsByDate(batch.weighings, currentDate);

                // Skip if there are no weighings for this day and no execution
                if (!execution && weighingsForDay.length === 0) {
                    continue;
                }

                // Create a new batch entry
                batchForDay = {
                    date: currentDate.format('YYYY-MM-DD'),
                    name: batch.name,
                    id: batch.id,
                    input: batch.input,
                    output: batch.output,
                    executions: [],
                    weighings: weighingsForDay // Include weighings for the day
                };

                result.push(batchForDay);
            }

            // Add the execution with daily duration (if present)
            if (execution && executionDuration > 0) {
                batchForDay.executions.push({
                    id: execution.id,
                    execution_type: execution.execution_type,
                    started: execution.started,
                    ended: execution.ended,
                    duration: executionDuration
                });
            }
        }
    }


    /**
     * Calculates the execution duration for a given day (bounded to day's start and end).
     */
    private calculateExecutionDuration(
        dayStart: moment.Moment,
        dayEnd: moment.Moment,
        executionStart: moment.Moment,
        executionEnd: moment.Moment
    ): number {
        const executionStartForDay = moment.max(dayStart, executionStart);
        const executionEndForDay = moment.min(dayEnd, executionEnd);
        return Math.ceil(
            executionEndForDay.diff(executionStartForDay, 'milliseconds') / 1000
        );
    }

    /**
     * Filters weighings for a specific day.
     */
    private filterWeighingsByDate(
        weighings: Weighing[],
        specificDate: moment.Moment
    ): Weighing[] {
        return weighings.filter(weighing =>
            moment(weighing.time).isSame(specificDate, 'day') // Use `time` for date comparison
        );
    }


    /**
     * Get durations grouped by execution type.
     */
    getGroupedExecutionDurations(batch: BatchToShowOnSingleDay): { [key: string]: string } {
        const groupedDurations = this.getGroupedExecutionDurationsRaw(batch);

        const formattedDurations: { [key: string]: string } = {};
        for (const type in groupedDurations) {
            if (groupedDurations.hasOwnProperty(type)) {
                formattedDurations[type] = this.functionsService.durationToString(groupedDurations[type]);
            }
        }

        return formattedDurations; // Return formatted durations (HH:mm:ss)
    }

    getGroupedExecutionDurationsRaw(batch: BatchToShowOnSingleDay): { [key: string]: number } {
        const groupedDurations: { [key: string]: number } = {};

        batch.executions.forEach(execution => {
            // Exclude unwanted execution types
            if (execution.execution_type === 'maintenance' || execution.execution_type === 'pause') {
                return;
            }

            // Combine "setup" and "teardown" into "Rüsten"
            let executionType = execution.execution_type;
            if (executionType === 'setup' || executionType === 'teardown') {
                executionType = 'Rüsten';
            }

            // Initialize and add duration
            if (!groupedDurations[executionType]) {
                groupedDurations[executionType] = 0;
            }

            groupedDurations[executionType] += execution.duration;
        });

        return groupedDurations; // Return raw numbers
    }


    private convertDurationStringToSeconds(duration: string): number {
        const [hours, minutes, seconds] = duration.split(':').map(Number);

        return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    }

    /**
     * Calculate the total duration sum of all execution types for a batch.
     */
    getExecutionTimeSum(batch: BatchToShowOnSingleDay): string {
        const groupedDurations = this.getGroupedExecutionDurations(batch);
        let totalDuration = 0; // Total duration in seconds

        for (const key in groupedDurations) {
            if (groupedDurations.hasOwnProperty(key)) {
                // Convert HH:mm:ss string to total seconds
                const durationInSeconds = this.convertDurationStringToSeconds(groupedDurations[key]);
                totalDuration += durationInSeconds;
            }
        }

        // Convert total seconds back to HH:mm:ss string for final display
        return this.functionsService.durationToString(totalDuration);
    }

    /**
     * Get the unique keys (execution types) for a batch's grouped durations.
     */
    getGroupedExecutionKeys(batch: BatchToShowOnSingleDay): string[] {
        return Object.keys(this.getGroupedExecutionDurations(batch));
    }


    public getMergedProcessTimes(batch: BatchToShowOnSingleDay): string[] {
        const batchDate = moment(batch.date, "YYYY-MM-DD");

        // Step 1: Filter out executions with irrelevant types (like "pause")
        const applicableExecutions = batch.executions
            .filter((execution) => {
                const started = moment(execution.started);
                const ended = moment(execution.ended);

                // Exclude "pause" executions and ensure execution overlaps with the batch date
                return (
                    execution.execution_type !== "pause" &&
                    execution.execution_type !== "maintenance" &&
                    started.isBefore(batchDate.clone().endOf("day")) &&
                    ended.isAfter(batchDate.clone().startOf("day"))
                );
            });

        // Step 2: Adjust execution times to fit within the batch date
        const adjustedExecutions = applicableExecutions.map((execution) => {
            const started = moment(execution.started);
            const ended = moment(execution.ended);

            // Clamp the start and end times to the bounds of the batch date
            const adjustedStart = moment.max(batchDate.clone().startOf("day"), started);
            const adjustedEnd = moment.min(batchDate.clone().endOf("day"), ended);

            return {start: adjustedStart, end: adjustedEnd};
        });

        // Step 3: Merge overlapping/connected time ranges
        adjustedExecutions.sort((a, b) => a.start.diff(b.start)); // Sort by start time

        const mergedTimes: { start: moment.Moment; end: moment.Moment }[] = [];
        for (const execution of adjustedExecutions) {
            if (
                mergedTimes.length > 0 &&
                execution.start.diff(mergedTimes[mergedTimes.length - 1].end, "seconds") <= 1
            ) {
                // Extend the last merged time range
                mergedTimes[mergedTimes.length - 1].end = moment.max(
                    mergedTimes[mergedTimes.length - 1].end,
                    execution.end
                );
            } else {
                // Create a new time range
                mergedTimes.push({start: execution.start, end: execution.end});
            }
        }

        // Step 4: Format the merged times into "HH:mm - HH:mm"
        return mergedTimes.map(
            (time) =>
                `${time.start.format("HH:mm")} - ${time.end.format("HH:mm")}`
        );
    }

    getGlobalExecutionKeys(): string[] {
        const keys = new Set<string>();
        this.batchesToShowInReport.forEach(batch => {
            this.getGroupedExecutionKeys(batch).forEach(key => keys.add(key));
        });
        return Array.from(keys);
    }

    getGlobalExecutionDurations(): { [key: string]: number } {
        const globalDurations: { [key: string]: number } = {};

        this.batchesToShowInReport.forEach(batch => {
            // Use raw durations for calculations
            const batchDurations: { [key: string]: number } = this.getGroupedExecutionDurationsRaw(batch);

            Object.keys(batchDurations).forEach(key => {
                globalDurations[key] = (globalDurations[key] || 0) + batchDurations[key];
            });
        });

        return globalDurations; // Return global numeric durations
    }

    getGlobalExecutionTotal(): number {
        return Object.values(this.getGlobalExecutionDurations()).reduce((sum, current) => sum + current, 0);
    }


    getWeighingsSumByProduct(): { [productName: string]: number } {
        return this.batchesToShowInReport.reduce((totals, batch) => {
            batch.weighings?.forEach((weighing) => {
                const productName = weighing.material.name || 'Unknown Product'; // Use product name or default
                const netWeight = weighing.net_weight || 0; // Safely extract the weight

                // Add the net weight to the appropriate product total
                totals[productName] = (totals[productName] || 0) + netWeight;
            });
            return totals;
        }, {} as { [productName: string]: number }); // Return an object with product-wise totals
    }

    getGroupedProducts(): { name: string; total: number }[] {
        const productSums = this.getWeighingsSumByProduct();
        return Object.keys(productSums).map((key) => ({
            name: key,
            total: productSums[key]
        }));
    }


    public round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    currentweek() {
        const now = moment();
        this.dateService.changeDate(now);
    }

    goCw(dir: number) {
        this.dateService.changeWeek(dir);
    }

    goYear(dir: number) {
        this.dateService.changeYear(dir);

    }


    public getOutputSubstitution(batch: any, mat: string) {
        if (mat) {
            if (batch.transfer) {
                if (batch.transfer.substitution) {
                    if (batch.transfer.substitution[mat]) {
                        if (batch.transfer.substitution[mat] !== 'none') {
                            return ' (' + batch.transfer.substitution[mat] + ')';
                        }
                    }
                }
            }
        }
        return '';
    }

    public getAllWeighings(batch: any): any[] {
        const weighingMap = new Map<string, { materialName: string; netWeight: number }>();

        if (batch.weighings) {
            batch.weighings.forEach(weighing => {
                const materialName = weighing.material?.name || 'Unknown';
                const netWeight = weighing.net_weight || 0;

                if (weighingMap.has(materialName)) {
                    // If materialName exists, sum up the netWeight
                    weighingMap.get(materialName)!.netWeight += netWeight;
                } else {
                    // If materialName doesn't exist, add a new entry
                    weighingMap.set(materialName, {
                        materialName: materialName,
                        netWeight: netWeight,
                    });
                }
            });
        }

        // Convert the map back to an array as the function output
        return Array.from(weighingMap.values());
    }


    public convertExecutionName(executionName: unknown): string {
        switch (executionName) {
            case 'processing': {
                return 'Aufbereiten ';
            }
            case 'pause': {
                return 'Pause ';
            }
            case 'maintenance': {
                return 'Instandhaltung ';
            }
            case 'malfunction': {
                return 'Störung ';
            }
            case 'Rüsten': {
                return 'Rüsten ';
            }
            default: {
                return 'default';
            }
        }
    }

    ngOnDestroy() {
        this.subscription$.unsubscribe();
    }
}
